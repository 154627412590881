<template>
<div class="app">
  <router-view v-if="!isLoggedIn"></router-view>
    <PromptCreator v-else></PromptCreator>
</div>
</template>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const isLoggedIn = ref(localStorage.getItem('isLoggedIn') === 'true');

    return {
      isLoggedIn
    };
  }
};
</script>