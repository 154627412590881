<template>
    <div>
     <section id="hero" class="hero-container">
        <!-- Hero section content -->
        <div class="hero-content">
          <div class="hero-text">
            <!-- Your text content goes here -->
            <img src="logo-white-trans.png" alt="PromptCue Logo" class="logo">
            <h1>Unlock the Power of Generative AI with Ease</h1>
            <p>Welcome to PromptCue — your ultimate companion for mastering advanced AI prompts. Designed for business users, our intuitive fill-in-the-blanks interface empowers you to create sophisticated prompts effortlessly. Stay ahead with the latest techniques and patterns, and transform your AI interactions.</p>
            <p>Learn, generate, and innovate with PromptCue.</p>
            <div class="features-header">
          <div class="feature-column">
            <img src="/icons/contact-form.png">
            <h3>Intuitive Prompt Creation</h3>
            <p>Craft advanced AI prompts effortlessly with our user-friendly fill-in-the-blanks interface. No technical expertise required!</p>
          </div>
          <div class="feature-column">
            <img src="/icons/rocket.png">
            <h3>Stay Ahead with Latest Techniques</h3>
            <p>Keep your AI game strong with the newest prompting techniques and patterns. We're always up-to-date, so you can be too!</p>
            </div>
            <div class="feature-column">
            <img src="/icons/learning.png">
            <h3>Learn the why and the how</h3>
            <p>Enhance your understanding of AI prompting with our rich library of written and video content. Gain insights into best practices and innovative approaches to maximize your AI's potential.</p>
          </div> 
          </div>
          <div class="hero-button-container">         
            <button @click="goToApp">Get Started</button>
          </div>
          </div>
        </div>
     </section>
  
     <section id="testimonials">
        <!-- Testimonials section content -->
        <div class="testimonials-container">
          <h2>Testimonials</h2>
          <div class="testimonial">
            <blockquote>"PromptCue has been a game-changer for my team's use of Gen AI tools! The easy fill-in-the-blanks interface makes creating advanced AI prompts a breeze, even for those of us without a technical background. The access to the latest techniques keeps us ahead in our AI projects, and let's us explore different ways to achieve our goals and truly expand our understanding. It's an invaluable tool for anyone looking to harness the power of generative AI."</blockquote>
          <cite>- Marketing Manager</cite>
          </div>
          <!-- Add more testimonials as needed -->
        </div>
      </section>
  
      <!-- 
      <section id="how-it-works">
        
        <div class="how-it-works-container">
          <h2>How It Works</h2>
          <p>Step by step guide on how our product makes things better.</p>
        </div>
      </section>
      -->
  
      <section id="cta">
        <!-- Call to action section content -->
        <div class="cta-container">
          <button class="button-body" @click="goToApp">Get Started</button>
        </div>
      </section>
  
      <footer>
        <div class="footer-container">
          <p>&copy; 2024 Unlok. All rights reserved.</p>
        </div>
      </footer>
    </div>
  </template>
  
  <script>
  export default {
    name: 'LandingPage',
    methods: {
      goToApp() {
        this.$router.push('/promptcreator');
      },
    },
  };
  </script>
  
  <style scoped>
    /* Additional styles for your components */
  
    .navbar {
      background-color: #FFFFFF;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
      font-family: 'Arial', sans-serif;
      padding: 10px 0;
    }
  
    .nav-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 20px;
    }
  
    .logo {
      height: 80px;
    }
  
    .nav-links {
      list-style: none;
      display: flex;
      align-items: center;
    }
  
    .nav-links li + li {
      margin-left: 20px;
    }
  
    .nav-links a {
      color: #333333;
      text-decoration: none;
      transition: color 0.3s ease;
    }
  
    .nav-links a:hover {
      color: #007BFF;
    }
  
    .hero-button-container {
      text-align: center;
    }
  
    .hero-text button {
      background-color: #FFFFFF; /* A bright blue that stands out */
      border: none !important;
      color: #FF8301;
      padding: 10px 20px;
      margin-top: 30px;
      margin-bottom: 20px;
      border-radius: 20px; /* Rounded corners */
      font-size: 16px; /* Slightly larger font size for readability */
      cursor: pointer;
      outline: none; /* Removes the outline to keep the flat design */
      transition: background-color 0.3s, transform 0.2s; /* Smooth transitions on hover */
    }
  
    .hero-text button:hover {
      background-color: #FF8301;
      color: #FFFFFF;
    }
  
    .hero-container {
      max-width: 100%;
      min-height: 600px; 
      margin: 0 auto;
      display: flex;
      justify-content: center;
      text-align: left;
      background-color: #FFFFFF;
      background-position: center;
      background: linear-gradient(rgba(255, 131, 1, 0.8), rgba(255, 131, 1, 0.8)),
                  url('../../public/hero_image.jpg') no-repeat center center;
      background-size: cover;
      z-index: 1;
    }
  
    .hero-content {
      display: flex; /* Use flexbox for inner layout */
      justify-content: center;
      width: 80%;
    }
    
    .hero-text {
      flex-basis: 100%;
      max-width: 1200px;
      flex-grow: 1;
    flex-shrink: 0;
      padding-right: 0px;
      padding-left: 0px;
      margin-top: 20px;
      color: #ffffff; /* Muted text color for the paragraph */
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-size: 18px; /* Slightly larger paragraph font size */
      line-height: 1.6; /* More readable line height */
      z-index: 2;
    }
  
    .hero-text h1{
      font-size: 40px;
      font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-weight: 200;
      margin-bottom: 10px;
      margin-top: 10px;
    }
  
    .hero-text p{
      font-size: 24px;
      font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-weight: 200;
      margin-top: 10px;
    }
  
    .features-header {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }
  
    .feature-column {
    flex: 1; /* Each column takes up equal width */
    padding-right: 20px;
    }
  
    .feature-column img {
       height: 40px;
    }
  
    .feature-column h3 {
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 22px;
        font-weight: 400 !important;
        margin-top: 10px;
    }
  
    .feature-column p {
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 200;
        font-size: 18px;
    }
  
    .features-container, .how-it-works-container, .testimonials-container, .cta-container {
      max-width: 1200px;
      margin-top: 0px;
      margin: 0 auto;
      padding: 20px;
      text-align: center;
    }
  
    .feature-list {
      list-style: none;
      /* Style your feature list */
    }
  
    .testimonial {
      background-color: #f8f8f8;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      padding: 20px;
      border-left: 5px solid rgb(255, 131, 1);
      margin: 20px 0;
    }

    .testimonials-container h2 {
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-weight: 400;
    }
  
    .testimonial blockquote {
      font-style: italic;
      font-weight: 200;
      font-size: 1.3em;
    }
  
    .testimonial cite {
      display: block;
      margin-top: 10px;
    }
  
    .button-body {
      background-color: #FF8301;
      border: none !important;
      color: #ffffff;
      padding: 10px 20px;
      margin-top: 30px;
      margin-bottom: 20px;
      border-radius: 20px;
      font-size: 16px;
      cursor: pointer;
      outline: none;
      transition: background-color 0.3s, transform 0.2s;
    }
  
    .footer-container {
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      text-align: center;
      padding: 20px 0;
      background-color: #333333;
      color: #FFFFFF;
    }
  
    @media (max-width: 768px) {
    .features-header {
      flex-direction: column;
    }
    .hero-text button {
      margin-bottom: 20px;
    }
    .hero-container {
      max-width: 100%;
  }
    }
  
    /* You can add more styling as needed */
  </style>
  