import { createRouter, createWebHistory } from 'vue-router'
import PromptCreator from '../components/PromptCreator'
import UserLogin from '../components/UserLogin.vue'
import LandingPage from '../components/LandingPage.vue'

const routes = [
  {
    path: "/login",
    name: "Login",
    component: UserLogin,
    meta: { requiresAuth: false }  // Only allow non-authenticated users to access this route
  },
  {
    path: "/",
    name: "Landing",
    component: LandingPage,
    meta: { requiresAuth: false }  // Only allow non-authenticated users to access this route
  },
  {
    path: '/',
    redirect: '/PromptCreator',
    meta: { requiresAuth: true }  // Only authenticated users can access this route
  },
  {
    path: "/PromptCreator",
    name: "PromptCreator",
    component: PromptCreator,
    meta: { requiresAuth: true }  // Only authenticated users can access this route
  },
  // Add other routes here
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  //console.log('beforeEach guard called');
  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
  //console.log('isLoggedIn:', isLoggedIn);
  if (to.meta.requiresAuth && !isLoggedIn) {
    //console.log('Redirecting to login page');
    next({ name: 'Login' }) // Redirect to login page if not authenticated
  } else {
    //console.log('Allowing access to route');
    next() // Allow access to the route
  }
})

export default router