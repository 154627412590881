import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import PromptCreator from './components/PromptCreator.vue';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { signOut as firebaseSignOut } from 'firebase/auth';

var firebaseConfig = {
  apiKey: "AIzaSyCe-BUfNPYuoatSVmY3FrDU33RQ52O43d0",
  authDomain: "promptcue.firebaseapp.com",
  projectId: "promptcue",
  storageBucket: "promptcue.appspot.com",
  messagingSenderId: "440840441612",
  appId: "1:440840441612:web:14e4551e3671042f3d8329"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);

onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in
    localStorage.setItem('isLoggedIn', true);
  } else {
    // User is signed out
    localStorage.setItem('isLoggedIn', false);
  }
});

const signOut = () => {
  console.log('signOut function called'); // Add this line
  firebaseSignOut(auth)
    .then(() => {
      console.log('signOut successful'); // And this line
      localStorage.removeItem('isLoggedIn');
      router.push('/');
    })
    .catch((error) => {
      console.error('signOut failed:', error); // And this line
    });
};

const app = createApp(App);
app.use(router);
app.component('PromptCreator', PromptCreator);
app.config.globalProperties.$signOut = signOut;
app.mount('#app');