<template>
  <div class="container">
    <div class="left-column">
      <div class="logo-container">
        <img src="logo.png" height="80px">
        <img src="logout.png" class="sign-out" title="Sign Out" alt="Sign Out" @click="signOut">
      </div>
      <div class="prompt-selection">
        <p>Select a prompt framework:</p>
        <select v-model="selectedFramework">
          <option v-for="(framework, index) in frameworks" :key="index" :value="framework">
            {{ framework.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="right-column">
      <!-- Embedded YouTube video -->
      <div v-if="!selectedFramework" class="video-container">
        <iframe width="800" height="450" src="https://www.youtube.com/embed/YefTILLNNzo" title="PromptCue - GenAI Prompting Tips" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
      
      <div v-else>
        <div class="description-card">
          <p class="description-title">{{ selectedFramework?.name }}</p>
          <div v-if="selectedFramework?.tags" class="tag-container">
            <span class="tag" v-for="(tag, tagIndex) in selectedFramework.tags" :key="tagIndex">{{ tag }}</span>
          </div>
          <p class="description-text">{{ selectedFramework?.description }}</p>
        </div>
        <form @submit.prevent="savePrompt">
          <p v-for="(input, index) in selectedFramework.inputs" :key="index" class="input-container">
            <label :for="input.name">{{ input.label }}</label>
            <textarea v-if="input.type === 'textarea'" :id="input.name" :placeholder="input.placeholder" v-model="prompt[input.name]"></textarea>
            <input v-else-if="input.type !== 'select'" :type="input.type" :id="input.name" :placeholder="input.placeholder" v-model="prompt[input.name]">
            <select v-else :id="input.name" v-model="prompt[input.name]">
              <option v-for="option in input.options" :key="option.value" :value="option.value">
                {{ option.label }}
              </option>
            </select>
          </p>
          <h3>Prompt statement:</h3>
          <div class="card">
            <div class="card-body">
              <p class="card-text" ref="statement" v-text="statement"></p>
              <p><button class="card-btn" @click="copyPrompt($event)">Copy Prompt</button></p>
            </div>
          </div>
        </form>
        <ul>
          <li v-for="(prompt, index) in prompts" :key="index">
            <p>{{ prompt.prompt }}</p>
            <p v-for="(value, key) in prompt" :key="key">
              {{ key }}: {{ value }}
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="toast.show" :class="'toast ' + toast.type" @click="toast.show = false">
      {{ toast.message }}
    </div>
  </div>
</template>

<script>
import frameworks from '../frameworks.json'

export default {
  data() {
    return {
      frameworks,
      selectedFramework: null,
      prompt: {},
        prompts: [],
        toast: {
          show: false,
          message: '',
          type: 'info'
        }
    }
  },
  computed: {
    statement() {
      if (this.selectedFramework) {
        let statement = this.selectedFramework.statement;
        for (const key in this.prompt) {
          const regex = new RegExp(`{{\\s*${key}\\s*}}`, 'g');
          statement = statement.replace(regex, this.prompt[key]);
        }
        return statement;
      }
      return '';
    },
    
  },
  methods: {
    selectFramework(framework) {
      this.selectedFramework = framework;
    },
    savePrompt() {
      const prompt = { ...this.prompt }
      localStorage.setItem('prompt', JSON.stringify(prompt))
      this.prompts.push(prompt)
      this.prompt = {}
      this.selectedFramework = null
    },
  
      showToast(message, type = 'info') {
        this.toast.message = message;
        this.toast.type = type;
        this.toast.show = true;
  
        setTimeout(() => {
          this.toast.show = false;
        }, 3000); // Hide the toast after 3 seconds
      },
  
    async copyPrompt(event) {
        event.preventDefault();
      try {
          const statement = this.$refs.statement.textContent;
          await navigator.clipboard.writeText(statement);
          this.showToast('Statement copied to clipboard', 'success');
      } catch (error) {
          console.error(error);
          this.showToast('Failed to copy to clipboard', 'error');
      }
    },
  signOut() {
      this.$signOut();
      window.location.reload();
    },
  created() {
    const prompts = JSON.parse(localStorage.getItem('prompts'))
    if (prompts) {
      this.prompts = prompts
    }
  }
}
}
</script>

<style scoped>

.container {
  display: flex;
  flex-direction: row;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0 auto;
  min-height: 100vh;
}

.left-column {
  flex: 1;
  border-right: 1px solid #ccc;
  max-width: 350px;
  min-height: 100%;
  padding-right: 10px;
}

.right-column {
  flex: 3;
  margin-top: 0px;
  height: 100%;
  padding-left: 50px;
  padding-right: 50px;
}

.logo-container {
  display: flex;
  justify-content: space-between;
    align-items: center;
  }
  .sign-out {
    display: block;
    cursor: pointer;
    height: 30px;
    margin-left: auto;
  }
  .prompt-selection {
    margin-left: 10px;
  }

  .video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  width: 80%; /* Ensure the container takes the full width of the parent */
}

.video-container iframe {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9; /* Maintain the aspect ratio of the video */
}
  
  @media (max-width: 768px)  {
    .logo-container img {
    height: 60px;
  }
  .sign-out {
    height: 30px !important;
  }
  .logo-container button {
    height: 30px !important;
    margin-top: 0px !important;
    padding: 5px !important;
  }

  .video-container {
    margin-top: 10px; /* Adjust margin for mobile */
    width: 100%;
  }
  
  .video-container iframe {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9; /* Maintain the aspect ratio of the video */
  }

  .container {
    flex-direction: column;
    min-height: 0;
  }
  .left-column,
  .right-column {
    flex: 1;
    padding: 0;
    border: none;
  }
  .prompt-selection {
    margin-left: 0px;
  }
  .right-column {
    margin-top: 10px;
  }
  .left-column {
    max-width: 100%;
  }
  form {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .description-card {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  textarea {
  width: 95% !important; /* set minimum width */
  }
}

.logo-container button {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  height: 40px;
  margin-top: 20px;
  background-color: #A9A9A9;
  margin-right: 0;
  
}

.promptselection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  margin: 0 auto;
}

.promptcard {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
  margin-left: 0;
  width: auto; /* Adjust this to change the card size */
  cursor: pointer;
  height: auto;
  text-align: left;
  margin-top: 0%;
  padding: 10px;
}

.promptcard.selected {
  background-color: rgba(255, 131, 1, 0.1); 
}

.promptcard h3{
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 0;
}

.framework-container {
  display: flex;
  flex-direction: column;
}

.tag-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
}

.tag {
  display: inline-block;
  background: rgba(255, 131, 1, 0.7);
  color: white;
  padding: 0.2rem 0.5rem;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 10px;
  font-size: 0.8rem;
}

h1 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

h3 {
  margin-top: 30px;
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 1.2rem
}
  
  p {
    font-size: 1.25rem;
    line-height: 1.5;
  }

  .description-text p {
    font-weight: 400;
    font-size: 1.1rem;
    margin-top: 0;
  }
  
  select {
    font-size: 1rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  form {
  margin-top: 1rem;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}
  
  input[type="text"], textarea {
    font-size: 1rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    width: 100%;
  }

  .input-container label {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  textarea {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  width: 98%; /* set minimum width */
  height: 80px; /* set starting height */
  resize: vertical; /* enable vertical resizing */
  }

  #output {
    font-size: 1.2rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
  font-size: 1rem;
  font-weight: 700;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background-color: #FF8301;
  color: #fff;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 30px;
}

button:hover {
  background-color: #D67200;
}
  
  ul {
    list-style-type: none;
    padding: 0;
    margin-top: 1rem;
  }
  
  li {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 1rem;
  }
  
  .statement {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  margin-top: 30px;
}

.card-body {
  display: flex;
  flex-direction: column;
}

.card-text {
  margin-bottom: 0;
  margin-top: 0;
}

.card-btn {
  background-color: #FF8301;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin: 0;
}

.card-btn:hover {
  background-color: #D67200;
}
  
  .description-card {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 0;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  
  }
  
  .description-title {
    margin-bottom: 0px;
    margin-top: 0;
    font-size: 1.8rem;
    font-weight: 600;
  }
  
  .description-text {
    margin: 0;
    font-size: 1rem;
  }
  
  .toast {
    font-family: Arial, Helvetica, sans-serif;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #333;
    color: #fff;
    font-size: 1rem;
    z-index: 9999;
  }
  
  .toast.success {
    background-color: rgb(0, 173, 0);
  }
  
  .toast.error {
    background-color: rgb(195, 0, 0);
  }
</style>